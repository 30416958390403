import { CONST_MENU } from "@/utils/constants";

let cachedMenuItems = null;
let lastFetchTime = 0;
const CACHE_DURATION = 3600000; // 1 hour in milliseconds

async function fetchMenuData() {
  const now = Date.now();
  // console.debug("Current time:", now);
  // console.debug("Last fetch time:", lastFetchTime);
  // console.debug("Time since last fetch:", now - lastFetchTime);

  if (cachedMenuItems && now - lastFetchTime < CACHE_DURATION) {
    // console.debug("Returning cached menu items");
    return cachedMenuItems;
  }

  // console.debug("Fetching new menu data from", CONST_MENU);

  try {
    const response = await fetch(CONST_MENU);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const res = await response.json();
    // console.debug("Fetched menu data:", res.data.length);

    cachedMenuItems = res.data;
    lastFetchTime = now;

    // console.debug("Updated cache with new menu items");
    return cachedMenuItems;
  } catch (ex) {
    // console.error("Error fetching menu data:", ex.message);
    return [];
  }
}

export default fetchMenuData;
