import Cookies from 'js-cookie';

export function getClientId() {
  const gaCookie = Cookies.get('_ga');
  if (gaCookie) {
    // Example parsing for client_id from the _ga cookie
    // Format: GA1.1.1234567890.1234567890
    // Extract the client_id from the cookie value (example parsing)
    const parts = gaCookie.split('.');
    if (parts.length >= 4) {
      const clientId = `${parts[2]}.${parts[3]}`; // Example extraction
      return clientId;
    }
  }
  return null; // Return null if client_id is not found
}
