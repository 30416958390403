"use client";
import Link from "next/link";
import Image from "next/image";
import React, { useState, useEffect } from "react";
import { CONST_NEWSLETTER } from "@/utils/constants";
import {
  FaFacebookF,
  FaTwitter,
  FaYoutube,
  FaInstagram,
  FaLongArrowAltRight,
} from "react-icons/fa";
import Wrapper from "./Wrapper";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
import { FaChevronUp, FaChevronDown, FaChevronRight } from "react-icons/fa";
import ScrollToTopButton from "./ScrollToTopButton";
import FooterContent from "./FooterContent";
import { useDispatch } from "react-redux";
import { fetchLocation } from "@/store/locationSlice";
import Icons from "./icons";
import { ChevronRight } from "lucide-react";

const Footer = () => {
  const [open, setOpen] = React.useState(0);
  const handleOpen = (value) => setOpen(open === value ? 0 : value);
  const [newsEmail, setNewsEmail] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [err_message, setErrorMessage] = React.useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchLocation());
  }, [dispatch]);

  const registerNewsletter = async () => {
    setErrorMessage("");
    setMessage("");
    try {
      if (!newsEmail) {
        setErrorMessage("Please enter email");

        return;
      } else if (!validateEmail(newsEmail)) {
        setErrorMessage("Please enter valid email");

        return;
      }

      const response = await fetch(CONST_NEWSLETTER, {
        method: "POST",
        body: JSON.stringify({ email: newsEmail, is_subscribe: true }),
      });
      const data = await response.json();
      if (response.status === 200) {
        setErrorMessage("");
        setMessage("Email registered.");
        setNewsEmail("");
      } else {
        setMessage("");
        setErrorMessage("Email already exist  ");
      }
    } catch (error) {
      // setMessage('Failed to update');
      console.error("Error making API call:", error);
    } finally {
      setTimeout(function () {
        setErrorMessage("");
        setMessage("");
      }, 5000);
    }
  };
  const validateEmail = (input) => {
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(input)) return false;
    else return true;
  };
  return (
    <div className="pb-[70px] bg-white">
      <footer className="bg-white text-brand-charcoal pb-[82px] hidden md:block border-t border-brand-medium-gray/30">
        <div className="bg-brand-uniwhite w-full">
          <Wrapper className="w-full flex items-center h-[211px]">
            <Icons.Logo className="cursor-pointer h-auto max-w-[160px] [--light:#f9f9f9] [--dark:#101010]" />
            <div className="ml-auto">
              <div className="min-w-[445px]">
                <p className="avenir-roman text-sm text-brand-charcoal/70 mb-2">
                  <strong>Say Hello to Surprises!</strong>
                </p>
                <p className="avenir-roman text-sm text-brand-charcoal/70 mb-[15px] text-center">
                  Sign up now to unlock exclusive discounts, special offers, and
                  more delightful deals. <Link href="/privacypolicy">*</Link>
                </p>

                <div className="relative">
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      registerNewsletter();
                    }}
                    className="flex border-2 border-brand-charcoal rounded-[3px] md:rounded-[5px] overflow-hidden max-h-[60px]"
                  >
                    <input
                      value={newsEmail}
                      onChange={(e) => {
                        setNewsEmail(e.target.value);
                        // reset message
                        setMessage("");
                        setErrorMessage("");
                      }}
                      type="text"
                      id="website-admin"
                      className="rounded-none bg-transparent focus:ring-0 outline-none block flex-1 min-w-0 w-full text-sm px-4"
                      placeholder="Enter you email here"
                    />
                    <button className="grid place-items-center bg-brand-charcoal">
                      <Icons.IconChevronRight className="size-full p-2 text-white">
                        <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
                      </Icons.IconChevronRight>
                    </button>
                  </form>
                  {
                    <div className="text-xs text-red-500 mt-1">
                      {err_message}
                    </div>
                  }
                  {
                    <div className="text-xs text-brand-dust-green mt-1">
                      {message}
                    </div>
                  }
                </div>
              </div>
            </div>
          </Wrapper>
        </div>
        <div className="mb-[70px] w-full border-t-[3px] border-t-brand-nebula"></div>
        <Wrapper className="flex justify-between flex-col md:flex-row gap-[50px] md:gap-0">
          <div className="w-full mx-auto flex flex-wrap justify-between text-center">
            <div className="w-full md:w-1/5 mb-4 md:mb-0 text-left">
              <h3 className="font-just-sans uppercase text-base mb-[20px]">
                SHOP BY
              </h3>
              <ul>
                <li className="avenir-roman text-base text-brand-charcoal/70 hover:text-brand-nebula mb-[15px]">
                  <Link href="/shopbyproducts">Shop By Products</Link>
                </li>
                <li className="avenir-roman text-base text-brand-charcoal/70 hover:text-brand-nebula mb-[15px]">
                  <Link href="/shopbybrands">Shop By Brands</Link>
                </li>
                <li className="avenir-roman text-base text-brand-charcoal/70 hover:text-brand-nebula mb-[15px]">
                  <Link href="/shopbycollection">Shop By Collection</Link>
                </li>
                <li className="avenir-roman text-base text-brand-charcoal/70 hover:text-brand-nebula mb-[15px]">
                  <Link href="/shopbythemes">Shop By Theme</Link>
                </li>
                <li className="avenir-roman text-base text-brand-charcoal/70 hover:text-brand-nebula mb-[15px]">
                  <Link href="/shopbycreators">Shop By Creators</Link>
                </li>
                <li className="avenir-roman text-base text-brand-charcoal/70 hover:text-brand-nebula mb-[15px]">
                  <Link href="/shopbyevents">Shop By Events</Link>
                </li>
              </ul>
            </div>
            <div className="w-full md:w-1/5 mb-4 md:mb-0 text-left">
              <h3 className="font-just-sans uppercase text-base mb-[20px]">
                COMPANY
              </h3>
              <ul>
                <li className="avenir-roman text-base text-brand-charcoal/70 hover:text-brand-nebula mb-[15px]">
                  <Link href="/ourstory">Our Story</Link>
                </li>
                <li className="avenir-roman text-base text-brand-charcoal/70 hover:text-brand-nebula mb-[15px]">
                  <Link href="/workwithus">Work With Us</Link>
                </li>
                <li className="avenir-roman text-base text-brand-charcoal/70 hover:text-brand-nebula mb-[15px]">
                  <Link href="/corporategifting">Corporate Gifting</Link>
                </li>
                <li className="avenir-roman text-base text-brand-charcoal/70 hover:text-brand-nebula mb-[15px]">
                  <Link href="/privacypolicy">Privacy Policy</Link>
                </li>
                <li className="avenir-roman text-base text-brand-charcoal/70 hover:text-brand-nebula mb-[15px]">
                  <Link href="/terms">Terms & Conditions</Link>
                </li>
                <li className="avenir-roman text-base text-brand-charcoal/70 hover:text-brand-nebula mb-[15px]">
                  <Link href="/storepolicies">Store Policies</Link>
                </li>
                <li className="avenir-roman text-base text-brand-charcoal/70 hover:text-brand-nebula mb-[15px]">
                  <Link href="/blog">Blog</Link>
                </li>
                {/* <li className="avenir-roman text-base text-brand-charcoal/70 hover:text-brand-nebula mb-[15px]">
                  <Link href="/brandchampions">Brand Champions</Link>
                </li> */}
                {/* <li className="avenir-roman text-base text-brand-charcoal/70 hover:text-brand-nebula mb-[15px]">
                  <Link href="#">Track Order</Link>
                </li> */}
              </ul>
            </div>
            <div className="w-full md:w-1/5 mb-4 md:mb-0 text-left">
              <h3 className="font-just-sans uppercase text-base mb-[20px]">
                SUPPORT
              </h3>
              <ul>
                <li className="avenir-roman text-base text-brand-charcoal/70 hover:text-brand-nebula mb-[15px]">
                  <Link href="/faq">Frequently Asked Questions</Link>
                </li>
                <li className="avenir-roman text-base text-brand-charcoal/70 hover:text-brand-nebula mb-[15px]">
                  <Link href="/warranty">Warranty Registration</Link>
                </li>
                <li className="avenir-roman text-base text-brand-charcoal/70 hover:text-brand-nebula mb-[15px]">
                  <Link href="/productguide">Product Usage Guide</Link>
                </li>
                <li className="avenir-roman text-base text-brand-charcoal/70 hover:text-brand-nebula mb-[15px]">
                  <Link href="/returnandrefundpolicy">
                    Return & Refund Policies
                  </Link>
                </li>
                <li className="avenir-roman text-base text-brand-charcoal/70 hover:text-brand-nebula mb-[15px]">
                  <Link href="/check-gc-balance">Check Giftcard Balance</Link>
                </li>
                <li className="avenir-roman text-base text-brand-charcoal/70 hover:text-brand-nebula mb-[15px]">
                  <Link href="/track-order">Track Order</Link>
                </li>
              </ul>
            </div>
            <div className="w-full md:w-1/5 mb-4 md:mb-0 text-left">
              <Link href="/contact" className="mb-5 flex items-center gap-2">
                <h3 className="font-just-sans uppercase text-base">CONTACT</h3>
                <ChevronRight className="text-brand-charcoal size-4" />
              </Link>
              <ul>
                <li className="avenir-roman text-base text-brand-charcoal/70 hover:text-brand-nebula mb-[15px]">
                  <span>Mon-Fri : 10:00 AM - 07:00 PM</span>
                </li>
                <li className="avenir-roman text-base text-brand-charcoal/70 hover:text-brand-nebula mb-[15px]">
                  <span>Sat : 10:00 AM - 02:30 PM</span>
                </li>
                <li className="avenir-roman text-base">
                  <Link
                    className="text-brand-charcoal/70 hover:text-brand-nebula mb-[15px]"
                    href="https://api.whatsapp.com/send?phone=917279999888&text=Hi,%20I%20have%20a%20query"
                  >
                    WhatsApp
                  </Link>{" "}
                  or{" "}
                  <Link
                    className="text-brand-charcoal/70 hover:text-brand-nebula mb-[15px]"
                    href="tel:+91-7279999888"
                  >
                    Call
                  </Link>{" "}
                  :{" "}
                  <Link
                    className="text-brand-charcoal/70 hover:text-brand-nebula mb-[15px]"
                    href="https://api.whatsapp.com/send?phone=917279999888&text=Hi,%20I%20have%20a%20query"
                  >
                    +917279999888
                  </Link>
                </li>
                <li className="avenir-roman text-base text-brand-charcoal/70 hover:text-brand-nebula mb-[15px]"></li>
                <li className="avenir-roman text-base text-brand-charcoal/70 hover:text-brand-nebula mb-[15px]">
                  <Link href="mailto:cs@macmerise.com">
                    Email : cs@macmerise.com
                  </Link>
                </li>
              </ul>
              <div className="mt-[40px]">
                <ul className="flex justify-start gap-4 items-center">
                  <li>
                    <Link href="https://www.instagram.com/macmerise/">
                      <Image
                        unoptimized
                        width={28}
                        height={28}
                        alt="Macmerise Instagram"
                        src="/social/insta.png"
                      />
                    </Link>
                  </li>
                  <li>
                    <Link href="https://www.facebook.com/macmerise">
                      <Image
                        unoptimized
                        width={24}
                        height={24}
                        alt="Macmerise Facebook"
                        src="/social/facebook.png"
                      />
                    </Link>
                  </li>
                  <li>
                    <Link href="https://twitter.com/macmerise">
                      <Image
                        unoptimized
                        width={26}
                        height={26}
                        alt="Macmerise Twiter"
                        src="/social/x.png"
                      />
                    </Link>
                  </li>
                  <li>
                    <Link href="https://www.youtube.com/channel/UC1eyGta27u5LVxiaD4BBlIg">
                      <Image
                        unoptimized
                        width={24}
                        height={24}
                        alt="Macmerise Youtube"
                        src="/social/youtube.png"
                      />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </Wrapper>
      </footer>
      <footer className="bg-white text-brand-charcoal pb-[35px] hidden xs:block sm:block md:hidden relative border-t border-brand-medium-gray/20">
        <div className="pt-14 bg-brand-uniwhite">
          <Wrapper className="flex justify-between flex-col md:flex-row gap-[10px] md:gap-0">
            <div className="w-fit mx-auto">
              <Icons.Logo className="cursor-pointer h-auto max-w-[160px] [--light:#f9f9f9] [--dark:#101010]" />
            </div>

            <p className="avenir-roman text-sm text-brand-charcoal/70 text-center md:text-left mt-5">
              <strong>Say Hello to Surprises!</strong>
            </p>
            <p className="avenir-roman text-sm text-brand-charcoal/70 text-center">
              Sign up now to unlock exclusive discounts, special offers, and
              more delightful deals. <Link href="/privacypolicy">*</Link>
            </p>

            <div className="relative mt-3">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  registerNewsletter();
                }}
                className="flex border-2 border-brand-charcoal rounded-[3px] md:rounded-[5px] overflow-hidden max-h-[60px]"
              >
                <input
                  value={newsEmail}
                  onChange={(e) => {
                    setNewsEmail(e.target.value);
                    // reset message
                    setMessage("");
                    setErrorMessage("");
                  }}
                  type="text"
                  id="website-admin"
                  className="rounded-none bg-transparent focus:ring-0 outline-none block flex-1 min-w-0 w-full text-sm px-4"
                  placeholder="Enter you email here"
                />
                <button className="grid place-items-center bg-brand-charcoal">
                  <Icons.IconChevronRight className="size-full p-2 text-white">
                    <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
                  </Icons.IconChevronRight>
                </button>
              </form>
              {<div className="text-xs text-red-500 mt-1">{err_message}</div>}
              {
                <div className="text-xs text-brand-dust-green mt-1">
                  {message}
                </div>
              }
            </div>
          </Wrapper>
        </div>
        <Wrapper className="flex justify-between flex-col md:flex-row gap-[10px] md:gap-0">
          <div className="text-center w-full">
            <div className="mt-[40px]">
              <ul className="flex justify-center items-center">
                <li className="mx-[12px]">
                  <Link href="https://www.instagram.com/macmerise/">
                    <Image
                      unoptimized
                      width={28}
                      height={28}
                      alt="Macmerise Instagram"
                      src="/social/insta.png"
                    />
                  </Link>
                </li>
                <li className="mx-[12px]">
                  <Link href="https://www.facebook.com/macmerise">
                    <Image
                      unoptimized
                      width={26}
                      height={26}
                      alt="Macmerise Facebook"
                      src="/social/facebook.png"
                    />
                  </Link>
                </li>
                <li className="mx-[12px]">
                  <Link href="https://twitter.com/macmerise">
                    <Image
                      unoptimized
                      width={29}
                      height={29}
                      alt="Macmerise Twiter"
                      src="/social/x.png"
                    />
                  </Link>
                </li>
                <li className="mx-[12px]">
                  <Link href="https://www.youtube.com/channel/UC1eyGta27u5LVxiaD4BBlIg">
                    <Image
                      unoptimized
                      width={27}
                      height={27}
                      alt="Macmerise Youtube"
                      src="/social/youtube.png"
                    />
                  </Link>
                </li>
              </ul>
            </div>
            <div className="mt-[40px] border-brand-medium-gray border-t mb-[20px]"></div>
            <ul className="mb-[20px]">
              {/* <li className="mb-[12px]">
                <Link
                  href=""
                  className="text-sm font-bold text-brand-charcoal avenir-black uppercase"
                >
                  Track Orders
                </Link>
              </li> */}
              <li>
                <Link
                  href="/blog"
                  className="text-sm font-bold text-brand-charcoal avenir-black uppercase"
                >
                  Blogs
                </Link>
              </li>
            </ul>
            <div className="border-brand-medium-gray border-t mb-[20px]"></div>
            <div className="mb-[15px]">
              <Accordion open={open === 1}>
                <div className="relative">
                  <AccordionHeader
                    className="text-sm text-brand-charcoal font-just-sans font-bold uppercase border-none justify-center avenir-black py-[5px] [&>span]:hidden"
                    onClick={() => handleOpen(1)}
                  >
                    Support
                    {open === 1 ? (
                      <FaChevronUp className="absolute right-0 top-[8px]" />
                    ) : (
                      <FaChevronDown className="absolute right-0 top-[10px]" />
                    )}
                  </AccordionHeader>
                </div>
                <AccordionBody className="py-[12px]">
                  <ul className="leading-loose text-brand-charcoal/70">
                    <li>
                      <Link href="/faq" className="text-[13px] capitalize">
                        Frequently Asked Questions
                      </Link>
                    </li>
                    <li>
                      <Link
                        href="/returnandrefundpolicy"
                        className="text-[13px] capitalize"
                      >
                        Refund & Returns
                      </Link>
                    </li>
                    <li>
                      <Link
                        href="/privacypolicy"
                        className="text-[13px] capitalize"
                      >
                        Privacy Policy
                      </Link>
                    </li>
                    <li>
                      <Link
                        href="/storepolicies"
                        className="text-[13px] capitalize"
                      >
                        Store Policies
                      </Link>
                    </li>
                    <li>
                      <Link href="/terms" className="text-[13px] capitalize">
                        Terms & Conditions
                      </Link>
                    </li>
                    <li>
                      <Link
                        href="/productguide"
                        className="text-[13px] capitalize"
                      >
                        Product Usage Guide
                      </Link>
                    </li>
                    <li>
                      <Link href="/warranty" className="text-[13px] capitalize">
                        Warranty Registration
                      </Link>
                    </li>

                    <li>
                      <Link
                        href="/check-gc-balance"
                        className="text-[13px] capitalize"
                      >
                        Check Giftcard Balance
                      </Link>
                    </li>

                    <li>
                      <Link
                        href="/track-order"
                        className="text-[13px] capitalize"
                      >
                        Track Order
                      </Link>
                    </li>
                  </ul>
                </AccordionBody>
              </Accordion>
            </div>
            <div className="border-brand-medium-gray border-t mb-[20px]"></div>
            <div className="mb-[20px]">
              <div className="relative">
                <Link
                  href="/contact"
                  className="text-sm font-bold text-brand-charcoal uppercase border-none justify-center avenir-black py-[5px]"
                >
                  Contact Us
                  <FaChevronRight className="absolute top-[5px] right-0" />
                </Link>
              </div>
              <ul className="leading-loose py-[12px] text-brand-charcoal/70">
                <li>
                  <Link
                    className="text-[13px] capitalize text-brand-charcoal/70 hover:text-brand-nebula mb-[15px]"
                    href="https://api.whatsapp.com/send?phone=917279999888&text=Hi,%20I%20have%20a%20query"
                  >
                    WhatsApp
                  </Link>{" "}
                  or{" "}
                  <Link
                    className="text-[13px] capitalize text-brand-charcoal/70 hover:text-brand-nebula mb-[15px]"
                    href="tel:+91-7279999888"
                  >
                    Call
                  </Link>{" "}
                  <Link
                    className="text-[13px] capitalize text-brand-charcoal/70 hover:text-brand-nebula mb-[15px]"
                    href="https://api.whatsapp.com/send?phone=917279999888&text=Hi,%20I%20have%20a%20query"
                  >
                    +917279999888
                  </Link>
                </li>
                <li>
                  <Link href="mailto:cs@macmerise.com" className=" text-[13px]">
                    Email : cs@macmerise.com
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          {/* ------ */}
        </Wrapper>
        <div className="w-full text-center justify-center avenir-medium text-[12px] text-brand-nebula">
          {new Date().getFullYear() - 1}-{new Date().getFullYear()} MCD Private
          Limited
        </div>
      </footer>
      {/* Uncomment this for Footer Content */}
      {/* <FooterContent /> */}
      <div className="">
        <ScrollToTopButton />
      </div>
    </div>
  );
};

export default Footer;
