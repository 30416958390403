import { configureStore } from "@reduxjs/toolkit";
import cartSlice from "./cartSlice";
import addressSlice from "./addressSlice";
import orderTypeSlice from "./orderTypeSlice";
import { combineReducers } from "redux";
import {
  persipersistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import discountSlice from "./discountSlice";
import locationSlice from "./locationSlice";
import userSlice from "./userSlice";
import giftCardSlice from "./giftCardSlice";
import shippingSlice from "./shippingSlice";
import modalSlice from "./modalSlice";
let persitConfig = {
  key: "mmroot",
  version: 1,
  storage,
};
let rootReducer = combineReducers({
  cart: cartSlice,
  address: addressSlice,
  type: orderTypeSlice,
  coupon: discountSlice,
  location: locationSlice,
  user: userSlice,
  giftcard: giftCardSlice,
  shipping: shippingSlice,
  modal: modalSlice,
});
let persistedReducer = persistReducer(persitConfig, rootReducer);
export default configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});
// export default configureStore({
//     reducer: {
//         cart: cartSlice,
//     },
// });
