import "@/styles/globals.css";
import "@/styles/font.css";
import "@/styles/react-intl.css";
import App from "next/app";
import Head from "next/head";
import { usePathname } from "next/navigation";
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import FooterContent from "@/components/FooterContent";
import HeaderBack from "@/components/HeaderBack";
import { Provider } from "react-redux";
import store from "@/store/store";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import Script from "next/script";
import { TailwindIndicator } from "@/components/ui/tw";
import { Suspense } from "react";
import fetchMenuData from "@/lib/fetchMenuData";
import useMediaQuery from "@/utils/mediaQuery";
import { usePreserveScroll } from "@/hooks/usePreserveScroll";
import Cookies from "js-cookie";
import React, { useEffect } from "react";
import ReactGA from 'react-ga4';
import useTracking from "@/utils/useTracking";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "@/lib/queryClient";
import GlobalModal from "@/components/global-modal";
import { schema } from "@/utils/seo/schema";
import { initalizeTractEvents } from "@/analytics-utils/eventTracker";
let persistor = persistStore(store);

const gaTrackingId = process.env.NEXT_PUBLIC_GA_TRACKING_ID;

if (gaTrackingId) {
  ReactGA.initialize(gaTrackingId);
} else {
  console.error('Google Analytics Tracking ID is not defined in environment variables.');
}

function MacmeriseApp({
  Component,
  pageProps: { session, ...pageProps },
  menuItems,
}) {
  const pathname = usePathname();
  // const winwidth = typeof window !== "undefined" ? window.innerWidth : null;
  const isMobile = useMediaQuery("md");
  usePreserveScroll();
  useTracking(pathname)

  useEffect(() => {
    initalizeTractEvents(true);
  }, [pathname]);
  
  useEffect(() => {
    (function () {
      var urlParams = new URLSearchParams(window.location.search);
      var fbclid = urlParams.get("fbclid");
      if (fbclid) {
        var expiryDate = new Date();
        expiryDate.setMonth(expiryDate.getMonth() + 3);
        document.cookie =
          "_fbc=fb.1." +
          Date.now() +
          "." +
          fbclid +
          "; expires=" +
          expiryDate.toUTCString() +
          "; path=/";
      }
    })();
  }, []);



  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const utmParams = {
      source: queryParams.get("utm_source"),
      medium: queryParams.get("utm_medium"),
      campaign: queryParams.get("utm_campaign"),
      term: queryParams.get("utm_term"),
      content: queryParams.get("utm_content"),
    };
    if (Object.values(utmParams).some((param) => param)) {
      Cookies.set("utm_params", JSON.stringify(utmParams));
    }
  }, []);

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_ENV === "production") {
      if (typeof fbq === "undefined") {
        !(function (f, b, e, v, n, t, s) {
          if (f.fbq) return;
          n = f.fbq = function () {
            n.callMethod
              ? n.callMethod.apply(n, arguments)
              : n.queue.push(arguments);
          };
          if (!f._fbq) f._fbq = n;
          n.push = n;
          n.loaded = !0;
          n.version = "2.0";
          n.queue = [];
          t = b.createElement(e);
          t.async = !0;
          t.src = v;
          s = b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t, s);
        })(
          window,
          document,
          "script",
          "https://connect.facebook.net/en_US/fbevents.js"
        );

        fbq("init", "2379085615614187");
        fbq("track", "PageView");
      }
      // Clarity script should only run in production
      (function (c, l, a, r, i, t, y) {
        c[a] = c[a] || function () {
          (c[a].q = c[a].q || []).push(arguments)
        };
        t = l.createElement(r);
        t.async = 1;
        t.src = "https://www.clarity.ms/tag/" + i;
        y = l.getElementsByTagName(r)[0];
        y.parentNode.insertBefore(t, y);
      })(window, document, "clarity", "script", "lbxo1sjg4s");
    }
  }, []);

  return (
    <>
      <Head>
        <title>
          Mobile Phone Cases, Buy MacBook Skins, Headphones, Best Mobile Cover
          &amp; Accessories Online in India | Macmerise
        </title>
        <meta
          name="description"
          content="Macmerie is India’s best online shopping website for accessories like mobile phone cases, best mobile cover, buy macbook skins &amp; designer headphones with easy shipping options anywhere in India."
        />
        {/* <meta name="viewport" content="width=device-width, initial-scale=1.0" /> */}
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
        {/* <link
          rel="icon"
          href="https://images.macmerise.com/images/mac_favicon.ico"
        /> */}
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css"
        />

        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
          crossOrigin="true"
        />

        {/* Social */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.macmerise.com/ourstory" />
        <meta property="og:site_name" content="Macmerise" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:locale:alternate" content="en_US" />

        {/* Twitter */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@macmerise" />
        <meta name="twitter:creator" content="@macmerise" />

      </Head>
      <Head>
        {schema.map((item, index) => (
          <script
            key={index}
            type="application/ld+json"
            dangerouslySetInnerHTML={{ __html: JSON.stringify(item) }}
          />
        ))}
      </Head>
      {/* <Script
        id="gtm-script"
        strategy="beforeInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){window.dataLayer.push(arguments)}
            gtag('js', new Date());
            gtag('config', 'GTM-NCWCW2KB');
          `,
        }}
      /> */}
      {process.env.NEXT_PUBLIC_ENV === "production" && (
        <Script
          src="https://toplyne-sdk.toplyne.io/index.js?token=1ef6f365286249eb91a2c2c9587bc717"
          strategy="afterInteractive"
          defer
        />
      )}

      {/* <Script
        id="clarity-script"
        strategy="afterInteractive"
      >
        {`(function(c,l,a,r,i,t,y){ c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)}; t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i; y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y); })(window, document, "clarity", "script", "lbxo1sjg4s");`}
      </Script> */}

      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <PersistGate persistor={persistor}>
            {/* <SessionProvider session={session}> */}
            {isMobile &&
              (pathname === "/cart" ||
                pathname === "/checkoutshipping" ||
                pathname === "/checkoutreview") ? (
              <>
                <HeaderBack pathname={pathname} />
                <Component {...pageProps} />
              </>
            ) : (
              <>
                <GlobalModal />
                <Suspense>
                  <Header menuItems={menuItems} />
                </Suspense>

                <Component {...pageProps} />
                <ReactQueryDevtools initialIsOpen={false} />
                <Footer />
                <FooterContent
                  content={pageProps?.data?.collection?.page_content}
                />
              </>
            )}
            <TailwindIndicator />
            {/* </SessionProvider> */}
          </PersistGate>
        </Provider>
      </QueryClientProvider>
    </>
  );
}

MacmeriseApp.getInitialProps = async (appContext) => {
  const appProps = await App.getInitialProps(appContext);
  const menuItems = await fetchMenuData();

  return { ...appProps, menuItems };
};

export default MacmeriseApp;
