import ReactGA from 'react-ga4';
import { getClientId } from './client-id';

export function sendGAEvent(eventName, eventData) {
  const clientId = getClientId();

  // Send event directly to Google Analytics
  ReactGA.event({
    category: eventData.category || 'General',
    action: eventName,
    label: eventData.label || '',
    value: eventData.value || 0,
    nonInteraction: eventData.nonInteraction || false,
  });

  // console.log(`${eventName} event sent to GA4 with client_id: ${clientId}`);
}

