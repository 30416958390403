import { useEffect } from 'react';
import ReactGA from 'react-ga4';

const useTracking = (pathname) => {
  useEffect(() => {
    // Track initial page load
    ReactGA.send({ hitType: 'pageview', page: pathname });

    // Scroll depth tracking
    const thresholds = [25, 50, 75, 100];
    let lastTriggered = 0;

    const handleScroll = () => {
      const scrollPosition = window.scrollY + window.innerHeight;
      const scrollPercentage = Math.round((scrollPosition / document.documentElement.scrollHeight) * 100);

      if (scrollPercentage > lastTriggered && scrollPercentage >= thresholds[0]) {
        const nextThreshold = thresholds.shift();
        if (nextThreshold) {
          ReactGA.event({
            category: 'Scroll',
            action: `${nextThreshold}% Scroll Depth`,
            label: pathname,
          });
          lastTriggered = nextThreshold;
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [pathname]);
};

export default useTracking;
